import React, { useEffect, useState } from 'react'
import { fetchAllDataAPI } from '../../utils/api';

const MostPopularLocation = () => {
    const [toplocation, setTopLocation] = useState([]);

    useEffect(() => {
        getTopLocation();
    }, [])

    const getTopLocation = () => {
        fetchAllDataAPI('sub-get-get-dashboard-top-location-data').then((res) => {
            if (res.data.status === 1) {
                setTopLocation(...[res.data.toplocation]);
            }
        })
    }

    return (
        <div className="topagentproduct card radius-10 overflow-hidden w-100">
            <div className="card-header border-bottom bg-transparent">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="headingtitle">
                        <h6>Most Popular Order Location</h6>
                    </div>
                </div>
            </div>
            <div className="topagentproductsection">
                {toplocation?.map((toplocation, index) => (
                    <div className="agentbody" key={index}>
                        <span className="name">{toplocation?.state}</span>
                        <span className="tcount">{toplocation?.total_count}</span>
                    </div>
                ))}

            </div>
        </div>
    )
}

export default MostPopularLocation