import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import { Link } from 'react-router-dom'
import { Table } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import * as XLSX from "xlsx";
import { addDataAPI } from '../utils/api'

const WhatsApp = () => {
    const [items, setItems] = useState([]);
    const [isLoading, setIsloading] = useState(false);

    const readExcel = (file) => {
        setIsloading(true);
        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);

            fileReader.onload = (e) => {
                const bufferArray = e.target.result;

                const wb = XLSX.read(bufferArray, { type: "buffer" });

                const wsname = wb.SheetNames[0];

                const ws = wb.Sheets[wsname];

                const data = XLSX.utils.sheet_to_json(ws);

                resolve(data);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });

        promise.then((d) => {
            setIsloading(false);
            setItems(d);
            addDataAPI('sub-get-add-whatsapp-data', d).then((res) => {
                console.log("whatsapp data",res);
            })
        });
    };

    return (
        <div className="misDashboard">
            <Helmet>
                <title>SATKARTAR:WHATSAPP</title>
            </Helmet>
            <ContentWrapper>
                <div className="page-wrapper breadcum">
                    <div className="page-breadcrumb d-none d-sm-flex align-items-center">
                        <div className="">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/dashboard">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item" aria-current="page">Whatsapp Data</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="customFilter">
                        <div className="card">
                            <div className="filterBody">
                                <div className="row">
                                    <div className="col-12 col-lg-12 col-xl-12">
                                        <form action="POST">
                                            <div className="row">
                                                <div className="col-12 col-lg-12 col-xl-3">
                                                    <label htmlFor="cname">Upload Excel File(csv formate)</label>
                                                    <input name="whatsapp" id="whatsapp" className="form-control" type="file" placeholder="Upload Excel File" onChange={(e) => {
                                                        const file = e.target.files[0];
                                                        readExcel(file);
                                                    }} />
                                                </div>
                                                <div className="col-12 col-lg-12 col-xl-3">
                                                    <label htmlFor="cname">Upload Excel File(csv formate)</label>
                                                    <input name="whatsapp" id="whatsapp" className="form-control" type="file" placeholder="Upload Excel File" onChange={(e) => {
                                                        const file = e.target.files[0];
                                                        readExcel(file);
                                                    }} />
                                                </div>
                                                <div className="col-12 col-lg-12 col-xl-3">
                                                    <label htmlFor="cname">Upload Excel File(csv formate)</label>
                                                    <input name="whatsapp" id="whatsapp" className="form-control" type="file" placeholder="Upload Excel File" onChange={(e) => {
                                                        const file = e.target.files[0];
                                                        readExcel(file);
                                                    }} />
                                                </div>
                                                <div className="col-12 col-lg-12 col-xl-3">
                                                    <label htmlFor="cname">Upload Excel File(csv formate)</label>
                                                    <input name="whatsapp" id="whatsapp" className="form-control" type="file" placeholder="Upload Excel File" onChange={(e) => {
                                                        const file = e.target.files[0];
                                                        readExcel(file);
                                                    }} />
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card tablecard chartsection">
                        <div className="table-responsive">
                            <Table size="sm" responsive="lg" striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Number</th>
                                        <th>Source</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {isLoading ?
                                        <>
                                            <tr>
                                                {Array.from({ length: 4 }).map((_, index) => (
                                                    <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                ))}
                                            </tr>
                                            <tr>
                                                {Array.from({ length: 4 }).map((_, index) => (
                                                    <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                ))}
                                            </tr>
                                            <tr>
                                                {Array.from({ length: 4 }).map((_, index) => (
                                                    <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                ))}
                                            </tr>
                                            <tr>
                                                {Array.from({ length: 4 }).map((_, index) => (
                                                    <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                ))}
                                            </tr>
                                            <tr>
                                                {Array.from({ length: 4 }).map((_, index) => (
                                                    <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                ))}
                                            </tr>
                                            <tr>
                                                {Array.from({ length: 4 }).map((_, index) => (
                                                    <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                ))}
                                            </tr>
                                            <tr>
                                                {Array.from({ length: 4 }).map((_, index) => (
                                                    <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                ))}
                                            </tr>
                                            <tr>
                                                {Array.from({ length: 4 }).map((_, index) => (
                                                    <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                ))}
                                            </tr>
                                            <tr>
                                                {Array.from({ length: 4 }).map((_, index) => (
                                                    <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                ))}
                                            </tr>
                                            <tr>
                                                {Array.from({ length: 4 }).map((_, index) => (
                                                    <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                ))}
                                            </tr>
                                        </>
                                        :
                                        items ?
                                            items?.map((items, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{items?.name}</td>
                                                    <td>{items?.number}</td>
                                                    <td>{items?.source}</td>
                                                </tr>
                                            ))
                                            :
                                            <p>Data not founds...</p>
                                    }
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Number</th>
                                        <th>Source</th>
                                    </tr>
                                </tfoot>
                            </Table>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default WhatsApp