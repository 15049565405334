import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import { Link, useNavigate } from 'react-router-dom';
import { addFormData, addFormDataAPI, fetchAllDataAPI, getDataAPIToPincode, updateDataAPI } from '../utils/api';
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';
import { FaPlus, FaRegEye, FaRegHandPointRight } from 'react-icons/fa';
import { CiExport } from "react-icons/ci";
import CsvDownloader from 'react-csv-downloader';
import { Button, Modal, Table } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import moment from 'moment';

const SocialMediaLead = () => {
    const [leaddata, setLeadData] = useState([]);
    const [isLoading, setIsloading] = useState(false);
    const subagentid = localStorage.getItem('subagentdata');
    const navigate = useNavigate();
    const [modalshow, setModalShow] = useState(false);
    const [formvalues, setFromData] = useState();
    const [pindata, setPindata] = useState([]);
    const [hiddend, setHiddenValue] = useState({});

    const [currentPage, setCurrentPage] = useState(1);
    const recordPerPage = 100;
    const lastIndex = currentPage * recordPerPage;
    const firstIndex = lastIndex - recordPerPage;
    const records = leaddata.slice(firstIndex, lastIndex);
    const npage = Math.ceil(leaddata.length / recordPerPage)
    const numbers = [...Array(npage + 1).keys()].slice(1)

    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        else {
            const data = { subagent_id: subagentid, social_name: formvalues.social_name, social_email: formvalues.social_email, social_mobile: formvalues.social_mobile, social_source: formvalues.social_source, social_date: formvalues.social_date, social_product: formvalues.social_product, social_qty: formvalues.social_qty, social_price: formvalues.social_price, social_pincode: formvalues.social_pincode, social_city: hiddend.social_city, social_state: hiddend.social_state, social_country: hiddend.social_country, social_status1: formvalues.social_status1, social_remarks: formvalues.social_remarks, social_plateform: formvalues.social_plateform, social_bolling_address: formvalues.social_bolling_address }
            if (data) {
                addFormDataAPI('sub-add-social-media-form-data', data).then((res) => {
                    if (res.data.status === 1) {
                        getAllSocialLead();
                        setModalShow(false);
                        toast.success("Lead has been added successfully!!")
                    }
                    else {
                        toast.warning("Check your connection!!");
                    }
                })
            }
            else {
                toast.warning("Check your connection!!");
            }
        }
        setValidated(true);
    };


    useEffect(() => {
        if (subagentid) {
            getAllSocialLead()
        }
        else {
            navigate("/");
        }
    }, [subagentid]);

    const getAllSocialLead = () => {
        setIsloading(true);
        fetchAllDataAPI('sub-get-social-media-form-data').then((res) => {
            if (res.data.status === 1) {
                setIsloading(false);
                setLeadData(...[res.data.lead]);
            }
            else {
                setIsloading(false);
                toast.warning("Check your connection")
            }
        })
    }

    const handleFormInputs = e => {
        setFromData({
            ...formvalues,
            [e.target.name]: e.target.value,
        });
    };
    const handleStatusTwo = socialid => (e) => {
        if (e.target.value.length) {
            const data = { social_id: socialid, social_status2: e.target.value }
            updateDataAPI('sub-update-social-media-status-data', data).then((res) => {
                if (res.data.status === 1) {
                    getAllSocialLead();
                    toast.success("Status has been added successfully!!")
                }
                else {
                    toast.warning("Check your connection!!");
                }
            })
        }
    };
    const handleStatusThree = socialid => (e) => {
        if (e.target.value.length) {
            const data = { social_id: socialid, social_status3: e.target.value }
            updateDataAPI('sub-update-social-media-status-data', data).then((res) => {
                if (res.data.status === 1) {
                    getAllSocialLead();
                    toast.success("Status has been added successfully!!")
                }
                else {
                    toast.warning("Check your connection!!");
                }
            })
        }
    };
    const handlePincode = e => {
        if (e.target.value.length === 6) {
            const pincode = e.target.value;
            getDataAPIToPincode('super-admin-get-data-according-to-pincode', pincode).then((res) => {
                if (res?.data.status === 1) {
                    setPindata(...[res?.data.pincode]);
                    setHiddenValue({ ...hiddend, social_city: res?.data.pincode.city_name, social_state: res?.data.pincode.state_name, social_country: res?.data.pincode.country_name });
                }
                else {
                    toast.warning("Check your connection!!");
                }
            })
        }
    }
    const columns = [
        {
            id: 'social_name',
            displayName: 'Name'
        },
        {
            id: 'social_mobile',
            displayName: 'Mobile Number'
        },
        {
            id: 'social_email',
            displayName: 'Email'
        },
        {
            id: 'social_source',
            displayName: 'Source'
        },
        {
            id: 'social_date',
            displayName: 'Lead Date'
        },
        {
            id: 'social_product',
            displayName: 'Product Name'
        },
        {
            id: 'social_qty',
            displayName: 'Product QTY'
        },
        {
            id: 'social_price',
            displayName: 'Product Price'
        },
        {
            id: 'social_pincode',
            displayName: 'Pincode'
        },
        {
            id: 'social_city',
            displayName: 'City'
        },
        {
            id: 'social_state',
            displayName: 'State'
        },
        {
            id: 'social_country',
            displayName: 'Country'
        },
        {
            id: 'social_status1',
            displayName: 'Status 1'
        },
        {
            id: 'social_remarks',
            displayName: 'Remarks'
        },
        {
            id: 'social_plateform',
            displayName: 'Plateform Name'
        },
        {
            id: 'social_bolling_address',
            displayName: 'Billing Address'
        },
        {
            id: 'social_status2',
            displayName: 'Status 2'
        },
        {
            id: 'social_status3',
            displayName: 'Status 3'
        }
    ];
    const exportOrder = leaddata;
    return (
        <div className="misDashboard">
            <Helmet>
                <title>SATKARTAR:MIS::All Social Media Lead</title>
            </Helmet>
            <ContentWrapper>
                <div className="page-wrapper breadcum">
                    <div className="page-breadcrumb d-none d-sm-flex align-items-center justify-content-between">
                        <div className="">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/dashboard">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item" aria-current="page">All Social Media Lead</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="d-flex">
                            <CsvDownloader filename="All Social Media Lead"
                                extension=".csv"
                                datas={exportOrder}
                                columns={columns}
                            >
                                <CiExport className="filtersvg" />
                                <button className="btn btn-info btn-sm btnfilter"> Export</button>
                            </CsvDownloader>
                            <Button onClick={() => setModalShow(true)} className="btn btn-sm btn-info"><FaPlus /> Add Lead</Button>
                        </div>
                    </div>
                    <Modal
                        show={modalshow}
                        onHide={() => setModalShow(false)}
                        dialogClassName="modal-90w"
                        aria-labelledby="example-custom-modal-styling-title"
                        size="lg"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="example-custom-modal-styling-title">
                                Social Media Lead
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form noValidate method="POST" validated={validated} onSubmit={handleSubmit}>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Enter the name"
                                            name="social_name"
                                            onChange={handleFormInputs}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid Name.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCustom02">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            required
                                            type="email"
                                            placeholder="Email Address"
                                            name="social_email"
                                            onChange={handleFormInputs}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            <FaRegHandPointRight /> Please provide a valid email address.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCustom02">
                                        <Form.Label>Mobile</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Mobile Number"
                                            name="social_mobile"
                                            pattern="[6789][0-9]{9}"
                                            maxLength={10}
                                            minLength={10}
                                            onChange={handleFormInputs}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            <FaRegHandPointRight /> Please provide a valid mobile number.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCustom03">
                                        <Form.Label>Source</Form.Label>
                                        <Form.Select required
                                            type="text"
                                            placeholder="Status"
                                            name="social_source"
                                            onChange={handleFormInputs}
                                        >
                                            <option value="">Select Source</option>
                                            <option value="Abandon Cart">Abandon Cart</option>
                                            <option value="Direct Whatsapp">Direct Whatsapp</option>
                                            <option value="Facebook Comment">Facebook Comment</option>
                                            <option value="Facebook Messenger">Facebook Messenger</option>
                                            <option value="Macamo Support">Macamo Support</option>
                                            <option value="Non-Confirmed">Non-Confirmed</option>
                                            <option value="Amazon">Amazon</option>
                                            <option value="Direct Call">Direct Call</option>
                                            <option value="Return Data">Return Data</option>
                                            <option value="Website Confirmed">Website Confirmed</option>
                                            <option value="Website Not Confirmed">Website Not Confirmed</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            <FaRegHandPointRight /> Please provide a valid source name.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCustom03">
                                        <Form.Label>Date</Form.Label>
                                        <Form.Control
                                            required
                                            type="date"
                                            placeholder="Date"
                                            name="social_date"
                                            onChange={handleFormInputs}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            <FaRegHandPointRight /> Please provide a valid date.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCustom03">
                                        <Form.Label>Product</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Product"
                                            name="social_product"
                                            onChange={handleFormInputs}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            <FaRegHandPointRight /> Please provide a valid Product.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCustom03">
                                        <Form.Label>QTY</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="QTY"
                                            name="social_qty"
                                            pattern="[0-9]"
                                            minLength={1}
                                            onChange={handleFormInputs}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            <FaRegHandPointRight /> Please provide a valid QTY.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCustom03">
                                        <Form.Label>Total Price</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Total Price"
                                            name="social_price"
                                            onChange={handleFormInputs}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            <FaRegHandPointRight /> Please provide a valid Total Price.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCustom03">
                                        <Form.Label>Pincode</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Pincode"
                                            name="social_pincode"
                                            onChange={handleFormInputs}
                                            pattern="[0-9]{6}"
                                            maxLength={6}
                                            minLength={6}
                                            onKeyUp={handlePincode}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            <FaRegHandPointRight /> Please provide a valid Pincode.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCustom03">
                                        <Form.Label>City</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="City"
                                            name="social_city"
                                            onChange={handleFormInputs}
                                            value={pindata?.city_name}
                                            readOnly={true}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            <FaRegHandPointRight /> Please provide a valid City.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCustom03">
                                        <Form.Label>State</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="State"
                                            name="social_state"
                                            onChange={handleFormInputs}
                                            value={pindata?.state_name}
                                            readOnly={true}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            <FaRegHandPointRight /> Please provide a valid State.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCustom03">
                                        <Form.Label>Country</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Country Name"
                                            name="social_country"
                                            onChange={handleFormInputs}
                                            value={pindata?.country_name}
                                            readOnly={true}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            <FaRegHandPointRight /> Please provide a valid Country.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCustom03">
                                        <Form.Label>Status</Form.Label>
                                        <Form.Select required
                                            type="text"
                                            placeholder="Status"
                                            name="social_status1"
                                            onChange={handleFormInputs}
                                        >
                                            <option value="">Select Status</option>
                                            <option value="Contacted on Whatsapp">Contacted on Whatsapp</option>
                                            <option value="Order Placed">Order Placed</option>
                                            <option value="No whatsapp number">No whatsapp number</option>
                                            <option value="Testing">Testing</option>
                                            <option value="Follow up">Follow up</option>
                                            <option value="call">call</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            <FaRegHandPointRight /> Please provide a valid mobile.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCustom03">
                                        <Form.Label>Remarks</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Remarks"
                                            name="social_remarks"
                                            onChange={handleFormInputs}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            <FaRegHandPointRight /> Please provide a valid Remarks.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCustom03">
                                        <Form.Label>Plateform</Form.Label>
                                        <Form.Select required
                                            type="text"
                                            placeholder="Plateform"
                                            name="social_plateform"
                                            onChange={handleFormInputs}
                                        >
                                            <option value="">Select Plateform</option>
                                            <option value="Macamo">Macamo</option>
                                            <option value="Vedabay">Vedabay</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            <FaRegHandPointRight /> Please provide a valid mobile.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="validationCustom03">
                                        <Form.Label>Billing Address</Form.Label>
                                        <Form.Control
                                            required
                                            as="textarea"
                                            type="text"
                                            placeholder="Billing Address"
                                            name="social_bolling_address"
                                            onChange={handleFormInputs}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            <FaRegHandPointRight /> Please provide a valid billing address.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Button type="submit">Submit</Button>
                            </Form>
                        </Modal.Body>
                    </Modal>
                    <div className="card tablecard chartsection">
                        <div className="table-responsive">
                            <Table responsive="lg" striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Mobile</th>
                                        <th>Product</th>
                                        <th>QTY</th>
                                        <th>Price</th>
                                        <th>Date</th>
                                        <th>State</th>
                                        <th>City</th>
                                        <th>Pincode</th>
                                        <th>Plateform Name</th>
                                        <th>Source</th>
                                        <th>Status1</th>
                                        <th>Status2</th>
                                        <th>Status3</th>
                                        <th>Address</th>
                                        <th>Create Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {isLoading ?
                                        <>
                                            <tr>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                            </tr>
                                            <tr>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                            </tr>
                                            <tr>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                            </tr>
                                            <tr>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                            </tr>
                                            <tr>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                            </tr>
                                            <tr>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                            </tr>
                                            <tr>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                            </tr>
                                            <tr>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                            </tr>
                                            <tr>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                            </tr>
                                            <tr>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                <td><Skeleton animation="wave" variant="text" /></td>
                                            </tr>
                                        </>

                                        :
                                        records?.map((records, index) => (
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td>{records?.social_name}</td>
                                                <td>{records?.social_email}</td>
                                                <td>{records?.social_mobile}</td>
                                                <td>{records?.social_product}</td>
                                                <td>{records?.social_qty}</td>
                                                <td>{records?.social_price}</td>
                                                <td><span className="badge badge-success">{moment(records?.social_date).format("MMM Do YY")}</span></td>
                                                <td>{records?.social_state}</td>
                                                <td>{records?.social_city}</td>
                                                <td>{records?.social_pincode}</td>
                                                <td>{records?.social_plateform}</td>
                                                <td>{records?.social_source}</td>
                                                <td><span className="badge badge-success">{records?.social_status1}</span></td>
                                                <td>
                                                    <div className="d-flex justify-content-between">
                                                        <span className="badge badge-warning badgestatus">{records?.social_status2}</span>
                                                        <Form.Group as={Col} md="4">
                                                            <Form.Select required
                                                                type="text"
                                                                className="socialstatustd"
                                                                placeholder="Status"
                                                                name="social_status2"
                                                                onChange={handleStatusTwo(records?.social_id)}
                                                            >
                                                                <option value="">Select Source</option>
                                                                <option value="Sale">Sale</option>
                                                                <option value="Call Not Connect">Call Not Connect</option>
                                                                <option value="Call No Sale">Call No Sale</option>
                                                                <option value="Call Sale">Call Sale</option>
                                                                <option value="Not Confirmed">Not Confirmed</option>
                                                                <option value="Address confirmation required">Address confirmation required</option>
                                                                <option value="Payment Pending">Payment Pending</option>
                                                                <option value="CONFIRM">CONFIRM</option>
                                                                <option value="Language Berrier">Language Berrier</option>
                                                                <option value="Call Cut">Call Cut</option>
                                                                <option value="Not Pic">Not Pic</option>
                                                                <option value="Switch off">Switch off</option>
                                                                <option value="Already">Already</option>
                                                                <option value="Busy">Busy</option>
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="d-flex justify-content-between">
                                                        <span className="badge badge-primary badgestatus">{records?.social_status3}</span>
                                                        <Form.Group as={Col} md="4">
                                                            <Form.Select required
                                                                type="text"
                                                                placeholder="Status"
                                                                className="socialstatustd"
                                                                name="social_status3"
                                                                onChange={handleStatusThree(records?.social_id)}
                                                            >
                                                                <option value="">Select Source</option>
                                                                <option value="Delivered">Delivered</option>
                                                                <option value="Dispatched">Dispatched</option>
                                                                <option value="RTO">RTO</option>
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </div>
                                                </td>
                                                <td>{records?.social_bolling_address}</td>
                                                <td><span className="badge badge-secondary">{moment(records?.social_create_date).format("MMM Do YY, h:mm A")}</span></td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Mobile</th>
                                        <th>Product</th>
                                        <th>QTY</th>
                                        <th>Price</th>
                                        <th>Date</th>
                                        <th>State</th>
                                        <th>City</th>
                                        <th>Pincode</th>
                                        <th>Plateform Name</th>
                                        <th>Source</th>
                                        <th>Status1</th>
                                        <th>Status2</th>
                                        <th>Status3</th>
                                        <th>Address</th>
                                        <th>Create Date</th>
                                    </tr>
                                </tfoot>
                            </Table>
                            <nav>
                                <ul className="pagination justify-content-end round-pagination">
                                    <li className="page-item">
                                        <a href="#" className="page-link" onClick={perPage}>Previous</a>
                                    </li>
                                    {
                                        numbers.map((n, i) => (
                                            <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
                                                <a href="#" className="page-link" onClick={() => changePage(n)}>{n}</a>
                                            </li>
                                        ))
                                    }
                                    <li className="page-item">
                                        <a href="#" className="page-link" onClick={nextPage}>Next</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
    function perPage() {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }
    function changePage(id) {
        setCurrentPage(id)
    }
    function nextPage() {
        if (currentPage !== npage) {
            setCurrentPage(currentPage + 1)
        }
    }
}

export default SocialMediaLead