import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.scss";
import Home from "./pages/Home";
import Error404 from "./pages/Error404";
import Dashboard from "./pages/Dashboard";
import MobileOTP from "./pages/MobileOTP";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import MyProfile from "./pages/MyProfile";
import 'react-loading-skeleton/dist/skeleton.css'
import 'mdbreact/dist/css/mdb.css';
import CMSOrder from "./pages/CMSOrders";
import AbandonedOrder from "./pages/AbandonedOrder";
import MacamoOrders from "./pages/MacamoOrders";
import Invoice from "./pages/Invoice";
import SocialMediaLead from "./pages/SocialMediaLead";
import WhatsApp from "./pages/WhatsApp";


function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/mobile-otp" element={<MobileOTP />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/my-profile" element={<MyProfile />} />
          <Route path="/cms-orders" element={<CMSOrder />} />
          <Route path="/all-orders" element={<MacamoOrders />} />
          <Route path="/abandoned-orders" element={<AbandonedOrder />} />
          <Route path="/invoice/:slug" element={<Invoice />} />
          <Route path="/social-media-lead" element={<SocialMediaLead />} />
          <Route path="/whatsapp" element={<WhatsApp />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer position="bottom-center" />
    </>
  );
}

export default App;
