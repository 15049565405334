import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import { Link, useNavigate } from 'react-router-dom';
import { addFormData, fetchAllDataAPI, getDataByID, updateOrderStatus } from '../utils/api';
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';
import { FaRegEye, FaRegHandPointRight } from 'react-icons/fa';
import { CiExport } from "react-icons/ci";
import CsvDownloader from 'react-csv-downloader';
import { Button, Form, Modal, Table, Col, InputGroup, Row } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { numberFormat } from '../components/numberFormat';

const MacamoOrders = () => {
    const [macamoorder, setMacamoorder] = useState([]);
    const [isLoading, setIsloading] = useState(false);
    const [filtervalue, setFilterValue] = useState();
    const [statusvalue, setStatusValue] = useState();
    const subagentid = localStorage.getItem('subagentdata');
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [orderstatus, setOrderStatus] = useState([]);
    const [validated, setValidated] = useState(false);

    const [historyshow, setHistoryShow] = useState(false);
    const handleHistoryClose = () => setHistoryShow(false);
    const [statushistory, setStatusHistory] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const recordPerPage = 500;
    const lastIndex = currentPage * recordPerPage;
    const firstIndex = lastIndex - recordPerPage;
    const records = macamoorder.slice(firstIndex, lastIndex);
    const npage = Math.ceil(macamoorder.length / recordPerPage)
    const numbers = [...Array(npage + 1).keys()].slice(1)


    useEffect(() => {
        if (subagentid) {
            getAllMacamoOrders()
            getOrderStatus()
        }
        else {
            navigate("/");
        }
    }, [subagentid]);

    const getAllMacamoOrders = () => {
        setIsloading(true);
        fetchAllDataAPI('sub-agent-get-all-macamo-orders').then((res) => {
            if (res.data.status === 1) {
                setIsloading(false);
                setMacamoorder(...[res.data.macamo]);
            }
            else {
                setIsloading(false);
                toast.warning("Check your connection")
            }
        })
    }
    const getOrderStatus = () => {
        fetchAllDataAPI('sub-get-order-status-data').then((res) => {
            if (res.data.status === 1) {
                setOrderStatus(...[res.data.orderstatus])
            }
        })
    }
    const columns = [
        {
            id: 'order_number',
            displayName: 'Order No.'
        },
        {
            id: 'cust_name',
            displayName: 'Name'
        },
        {
            id: 'cust_phone',
            displayName: 'Mobile'
        },
        {
            id: 'subtotal_price',
            displayName: 'Total Price'
        },
        {
            id: 'created_date',
            displayName: 'Order date'
        },
        {
            id: 'created_time',
            displayName: 'Order Time'
        },
        {
            id: 'state',
            displayName: 'State'
        },
        {
            id: 'city',
            displayName: 'City'
        },
        {
            id: 'pincode',
            displayName: 'Pincode'
        },
        {
            id: 'vendor_name',
            displayName: 'Plateform Name'
        },
        {
            id: 'payment_method',
            displayName: 'Payment Method'
        },
    ];
    const exportOrder = macamoorder;
    const handleFilter = async (e) => {
        setIsloading(true);
        e.preventDefault();
        if (filtervalue) {
            addFormData('sub-agent-sort-all-macamo-order', filtervalue).then((res) => {
                if (res.data.status === 1) {
                    setIsloading(false);
                    setMacamoorder(res.data.filterdata);
                }
            })
        }
        else {
            getAllMacamoOrders()
        }
    };
    const resetOrder = () => {
        getAllMacamoOrders()
    }
    const handleInputs = e => {
        setFilterValue({
            ...filtervalue,
            [e.target.name]: e.target.value,
        });
    };
    const handleOrderPending = (orderid) => {
        setShow(true)
        localStorage.setItem("orderid", orderid)
    }
    const handleStatusInputs = e => {
        setStatusValue({
            ...statusvalue,
            [e.target.name]: e.target.value,
        });
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        else {
            let orderid = localStorage.getItem("orderid");
            const data = { orderid: orderid, orderstatus: statusvalue.orderstatus, orderremarkss: statusvalue.orderremarks }
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'danger',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, update it!',
                cancelButtonText: 'No, cancel!',
            }).then((result) => {
                if (result.isConfirmed) {
                    updateOrderStatus('sub-update-pending-order-status-data', data).then((resp) => {
                        if (resp.data.status === 1) {
                            Swal.fire(
                                'Updated!',
                                'Your file has been updated.',
                                'success'
                            )
                            setShow(false)
                            getAllMacamoOrders()
                        }
                    })
                }
                else if (
                    result.dismiss === Swal.DismissReason.cancel
                ) {
                    Swal.fire(
                        'Cancelled',
                        'Your imaginary file is safe :)',
                        'error'
                    )
                }
            })
        }
        setValidated(true);
    };
    const handleOrderStatusHistory = (orderid) => {
        setHistoryShow(true);
        getDataByID('sub-get-order-status-history-data', orderid).then((res) => {
            if (res.data.status === 1) {
                setStatusHistory(...[res.data.history]);
            }
        })
    }
    return (
        <div className="misDashboard">
            <Helmet>
                <title>SATKARTAR:MIS::ALL ORDER</title>
            </Helmet>
            <ContentWrapper>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Update Delivery Status</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="12" controlId="validationCustom01">
                                    <Form.Label>Select Status</Form.Label>
                                    <Form.Select name="orderstatus" required type="text" onChange={handleStatusInputs}>
                                        <option value="">Select Status</option>
                                        {orderstatus?.map((orderstatus, index) => (
                                            <option key={index} value={orderstatus?.id}>{orderstatus?.order_status}</option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        <FaRegHandPointRight /> Please choose a delivery status.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="12" controlId="validationCustom02">
                                    <Form.Label>Remarks</Form.Label>
                                    <Form.Control as="textarea" rows={3}
                                        required
                                        type="text"
                                        placeholder="Remarks"
                                        onChange={handleStatusInputs}
                                        name="orderremarks"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        <FaRegHandPointRight /> Please enter the remarks.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Button type="submit" className="btn-sm">Submit form</Button>
                        </Form>
                    </Modal.Body>
                </Modal>
                <Modal show={historyshow} onHide={handleHistoryClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Delivery Status History</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Table size="sm" responsive="sm" striped bordered hover>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Delivery Status</th>
                                    <th>Remarks</th>
                                    <th>Date</th>
                                    <th>Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                {statushistory?.map((statushistory, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{statushistory?.order_status}</td>
                                        <td>{statushistory?.orderremarkss}</td>
                                        <td>{statushistory?.history_date}</td>
                                        <td>{statushistory?.history_time}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Modal.Body>
                </Modal>
                <div className="page-wrapper breadcum">
                    <div className="page-breadcrumb d-none d-sm-flex align-items-center">
                        <div className="">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/dashboard">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item" aria-current="page">All Orders</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="customFilter">
                        <div className="card">
                            <div className="filterBody">
                                <div className="row">
                                    <div className="col-12 col-lg-12 col-xl-12">
                                        <form action="POST" onSubmit={handleFilter}>
                                            <div className="row">
                                                <div className="col-12 col-lg-12 col-xl-3">
                                                    <label htmlFor="bname">Brand Name</label>
                                                    <select className="form-control" id="bname" type="text" name="bname" onChange={handleInputs}>
                                                        <option value="">Select Brand Name</option>
                                                        <option value="Vedabay">Vedabay</option>
                                                        <option value="Macamo">Macamo</option>
                                                    </select>
                                                </div>
                                                <div className="col-12 col-lg-12 col-xl-3">
                                                    <label htmlFor="cmobile">Mobile Number</label>
                                                    <input name="cmobile" id="cmobile" className="form-control" type="text" placeholder="Search by Mobile Number" onChange={handleInputs} />
                                                </div>
                                                <div className="col-12 col-lg-12 col-xl-3">
                                                    <label htmlFor="onumber">Order Number</label>
                                                    <input name="onumber" id="onumber" className="form-control" type="text" placeholder="Search by Order Number" onChange={handleInputs} />
                                                </div>
                                                <div className="col-12 col-lg-12 col-xl-3">
                                                    <label htmlFor="dstatus">Delivery Status</label>
                                                    <select className="form-control" id="dstatus" type="text" name="dstatus" onChange={handleInputs}>
                                                        <option value="">Select Status</option>
                                                        {orderstatus?.map((orderstatus, index) => (
                                                            <option key={index} value={orderstatus?.id}>{orderstatus?.order_status}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="col-12 col-lg-12 col-xl-3">
                                                    <label htmlFor="sdate">Start Date</label>
                                                    <input name="sdate" id="sdate" className="form-control" type="date" placeholder="Search by Order Date" onChange={handleInputs} />
                                                </div>
                                                <div className="col-12 col-lg-12 col-xl-3">
                                                    <label htmlFor="edate">End Date</label>
                                                    <input name="edate" id="edate" className="form-control" type="date" placeholder="Search by Order Date" onChange={handleInputs} />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-lg-12 col-xl-2">
                                                    <button type="submit" className="btn btn-success btn-md btnfilter">Filter</button>
                                                </div>
                                                <div className="col-12 col-lg-12 col-xl-2">
                                                    <span onClick={resetOrder} className="btn btn-warning btn-md btnfilter">Reset</span>
                                                </div>
                                                <div className="col-12 col-lg-12 col-xl-2">
                                                    <CsvDownloader filename="All Orders"
                                                        extension=".csv"
                                                        datas={exportOrder}
                                                        columns={columns}
                                                    >
                                                        <CiExport className="filtersvg" />
                                                        <button className="btn btn-info btn-md btnfilter"> Export</button>
                                                    </CsvDownloader>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="col-12 col-lg-12 col-xl-12">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card tablecard chartsection">
                        <div className="table-responsive">
                            <Table className="table-sm" size="sm" responsive="sm" striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Order No.</th>
                                        <th>Name</th>
                                        <th>Mobile</th>
                                        <th>Price</th>
                                        <th>Order Date</th>
                                        <th>Order Time</th>
                                        <th>State</th>
                                        <th>City</th>
                                        <th>Pincode</th>
                                        <th>Plateform Name</th>
                                        <th>Order Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {isLoading ?
                                        <>
                                            <tr>
                                                {Array.from({ length: 13 }).map((_, index) => (
                                                    <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                ))}
                                            </tr>
                                            <tr>
                                                {Array.from({ length: 13 }).map((_, index) => (
                                                    <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                ))}
                                            </tr>
                                            <tr>
                                                {Array.from({ length: 13 }).map((_, index) => (
                                                    <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                ))}
                                            </tr>
                                            <tr>
                                                {Array.from({ length: 13 }).map((_, index) => (
                                                    <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                ))}
                                            </tr>
                                            <tr>
                                                {Array.from({ length: 13 }).map((_, index) => (
                                                    <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                ))}
                                            </tr>
                                            <tr>
                                                {Array.from({ length: 13 }).map((_, index) => (
                                                    <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                ))}
                                            </tr>
                                            <tr>
                                                {Array.from({ length: 13 }).map((_, index) => (
                                                    <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                ))}
                                            </tr>
                                            <tr>
                                                {Array.from({ length: 13 }).map((_, index) => (
                                                    <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                ))}
                                            </tr>
                                            <tr>
                                                {Array.from({ length: 13 }).map((_, index) => (
                                                    <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                ))}
                                            </tr>
                                            <tr>
                                                {Array.from({ length: 13 }).map((_, index) => (
                                                    <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                ))}
                                            </tr>
                                        </>

                                        :
                                        records?.map((records, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td><span onClick={() => handleOrderStatusHistory(records?.order_id)} className="badge badge-success statushistory">{records?.order_number}</span></td>
                                                <td>{records?.cust_name}</td>
                                                <td>{records?.cust_phone}</td>
                                                <td>{numberFormat(records?.subtotal_price)}</td>
                                                <td><span className="badge badge-info">{records?.created_date}</span></td>
                                                <td>{records?.created_time}</td>
                                                <td>{records?.state}</td>
                                                <td>{records?.city}</td>
                                                <td>{records?.pincode}</td>
                                                <td><span className="badge badge-secondary">{records?.vendor_name}</span></td>
                                                <td>
                                                    {(() => {
                                                        if (records?.order_status === "Pending") {
                                                            return (
                                                                <button onClick={() => handleOrderPending(records?.order_id)} className="badge badge-warning btnorderstatus">{records?.order_status}</button>
                                                            )
                                                        }
                                                        else if (records?.order_status === "Confirmed") {
                                                            return (
                                                                <button onClick={() => handleOrderPending(records?.order_id)} className="badge badge-success btnorderstatus">{records?.order_status}</button>
                                                            )
                                                        }
                                                        else {
                                                            return (
                                                                <button onClick={() => handleOrderPending(records?.order_id)} className="badge badge-primary btnorderstatus">{records?.order_status}</button>
                                                            )
                                                        }
                                                    })()}
                                                </td>
                                                <td><Link to={`/invoice/${records?.order_number}`} className="badge badge-success"><FaRegEye style={{ fontSize: "20px" }} /></Link></td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th>#</th>
                                        <th>Order No.</th>
                                        <th>Name</th>
                                        <th>Mobile</th>
                                        <th>Price</th>
                                        <th>Order Date</th>
                                        <th>Order Time</th>
                                        <th>State</th>
                                        <th>City</th>
                                        <th>Pincode</th>
                                        <th>Plateform Name</th>
                                        <th>Order Status</th>
                                        <th>Action</th>
                                    </tr>
                                </tfoot>
                            </Table>
                            <nav>
                                <ul className="pagination justify-content-end round-pagination">
                                    <li className="page-item">
                                        <a href="#" className="page-link" onClick={perPage}>Previous</a>
                                    </li>
                                    {
                                        numbers.map((n, i) => (
                                            <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
                                                <a href="#" className="page-link" onClick={() => changePage(n)}>{n}</a>
                                            </li>
                                        ))
                                    }
                                    <li className="page-item">
                                        <a href="#" className="page-link" onClick={nextPage}>Next</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
    function perPage() {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }
    function changePage(id) {
        setCurrentPage(id)
    }
    function nextPage() {
        if (currentPage !== npage) {
            setCurrentPage(currentPage + 1)
        }
    }
}

export default MacamoOrders