import React, { useEffect, useState } from 'react'
import { addFormData, fetchAllDataAPI } from '../../utils/api';
import Skeleton from 'react-loading-skeleton';

const CheckDelivery = () => {
    const [filtervalue, setFilterValue] = useState();
    const [isLoading, setIsloading] = useState(false);
    const [order, setOrder] = useState([]);
    const [totalorder, setTotalOrder] = useState([]);

    useEffect(() => {
        getAllOrders()
    }, []);

    const handleInputs = e => {
        setFilterValue({
            ...filtervalue,
            [e.target.name]: e.target.value,
        });
    };

    const getAllOrders = () => {
        setIsloading(true);
        fetchAllDataAPI('sub-get-all-delivery-ration').then((res) => {
            if (res.status === 200) {
                setIsloading(false);
                setOrder(...[res.data.ratioorder])
                setTotalOrder(...[res.data.totalorder])
            }
            else {
                setIsloading(false);
                setOrder("");
            }
        })
    }

    const handleFilter = async (e) => {
        setIsloading(true);
        e.preventDefault();
        if (filtervalue) {
            addFormData('sub-get-filter-delivery-ration', filtervalue).then((res) => {
                if (res.data.status === 1) {
                    setIsloading(false);
                    setOrder(res.data.ratioorder);
                    setTotalOrder(...[res.data.totalorder])
                }
                else {
                    setIsloading(false);
                    setOrder("");
                }
            })
        }
        else {
            getAllOrders()
        }
    };


    return (
        <div className="topagentproduct card radius-10 overflow-hidden w-100">
            <div className="card-header border-bottom bg-transparent">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="headingtitle">
                        <h6>Delivery Ratio ({totalorder})</h6>
                        <form className="ratioform" onSubmit={handleFilter}>
                            <div className="inputratio">
                                <select className="form-control" id="bname" type="text" name="bname" onChange={handleInputs}>
                                    <option value="">Select Brand</option>
                                    <option value="Vedabay">Vedabay</option>
                                    <option value="Macamo">Macamo</option>
                                </select>
                            </div>
                            <div className="inputratio">
                                <input name="sdate" id="sdate" className="form-control" type="date" placeholder="Search by Start Date" onChange={handleInputs} />
                            </div>
                            <div className="inputratio">
                                <input name="edate" id="edate" className="form-control" type="date" placeholder="Search by End Date" onChange={handleInputs} />
                            </div>
                            <div className="inputratio">
                                <button className="btn btn-sm btn-primary">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="topagentproductsection">
                {isLoading ?
                    <>
                        <div className="agentbody">
                            {Array.from({ length: 2 }).map((_, index) => (
                                <span key={index}>
                                    <Skeleton animation="wave" variant="text" />
                                </span>
                            ))}
                        </div>
                        <div className="agentbody">
                            {Array.from({ length: 2 }).map((_, index) => (
                                <span key={index}>
                                    <Skeleton animation="wave" variant="text" />
                                </span>
                            ))}
                        </div>
                        <div className="agentbody">
                            {Array.from({ length: 2 }).map((_, index) => (
                                <span key={index}>
                                    <Skeleton animation="wave" variant="text" />
                                </span>
                            ))}
                        </div>
                    </>
                    :
                    order ?
                        order?.map((order, index) => (
                            <div className="agentbody" key={index}>
                                <span className="name">{order?.shipment_status}</span>
                                <span className="tcount">{Number((order?.total / totalorder) * 100).toFixed(2)} %</span>
                            </div>
                        ))
                        :
                        <p>Data not founds...</p>
                }
            </div>
        </div>
    )
}

export default CheckDelivery