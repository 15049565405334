import React, { useEffect, useState } from 'react'
import { fetchAllDataAPI } from '../../utils/api';

const TopProductSale = () => {
    const [topproduct, setTopProduct] = useState([]);

    useEffect(() => {
        getTopProduct();
    }, [])

    const getTopProduct = () => {
        fetchAllDataAPI('sub-get-get-dashboard-top-product-data').then((res) => {
            if (res.data.status === 1) {
                setTopProduct(...[res.data.topproduct]);
            }
        })
    }
    return (
        <div className="topagentproduct card radius-10 overflow-hidden w-100">
            <div className="card-header border-bottom bg-transparent">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="headingtitle">
                        <h6>Top Selling Products</h6>
                    </div>
                </div>
            </div>
            <div className="topagentproductsection">
                {topproduct?.map((topproduct,index) => (
                    <div className="agentbody" key={index}>
                        <span className="name">{topproduct?.product_name}</span>
                        <span className="tcount">{topproduct?.total_count}</span>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default TopProductSale