import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import macamoimg from "../assets/macamo.avif";
import vedabayimg from "../assets/vedabay.webp";
import { useNavigate, useParams } from 'react-router-dom';
import { getSingleDataFromDatabase } from '../utils/api';

const Invoice = () => {
    const { slug } = useParams();
    const [invoicedata, setInvoice] = useState([]);
    const [pinvoicedata, setPInvoice] = useState([]);
    const subagentid = localStorage.getItem('subagentdata');
    const navigate = useNavigate();

    useEffect(() => {
        if (subagentid) {
            getInvoiceData(slug)
        }
        else {
            navigate("/");
        }
    }, [subagentid,slug]);

    const getInvoiceData = (slug) => {
        getSingleDataFromDatabase(`sub-agent-invoice-order-data/${slug}`).then((res) => {
            if (res.data.status === 1) {
                setInvoice(...[res.data.invoice]);
                setPInvoice(...[res.data.pinvoice]);
            }
        })
    }

    return (
        <div className="invoice">
            <Helmet>
                <title>SATKARTAR::INVOICE</title>
            </Helmet>
            <ContentWrapper>
                <div className="row">
                    <div className="col-12 col-lg-12 col-xl-12">
                        <div className="card radius-10 overflow-hidden w-100">
                            <div className="invoiceSection">
                                <div className="header">
                                    <div className="row">
                                        <div className="col-12 col-lg-6 col-xl-6">
                                            <div className="logo mt-2">
                                            {(() => {
                                                if(invoicedata?.vendor_name === "Macamo"){
                                                    return(
                                                        <img src={macamoimg} alt={macamoimg} />
                                                    )
                                                }
                                                else{
                                                    return(
                                                        <img src={vedabayimg} alt={vedabayimg} />
                                                    )
                                                }
                                            })()}
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-6 col-xl-6">
                                            <div className="invoice">
                                                <h2>Invoice</h2>
                                                <p>Date:24 Jan 2024</p>
                                                <p>Invoice#:3254</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-lg-6 col-xl-6">
                                            <div className="billing">
                                                <h5>Billing TO</h5>
                                                <div>
                                                    <div className="billingtr">
                                                        <p>Name:</p>
                                                        <p>SK Sarthi Private Limited</p>
                                                    </div>
                                                    <div className="billingtr">
                                                        <p>City:</p>
                                                        <p>New Delhi</p>
                                                    </div>
                                                    <div className="billingtr">
                                                        <p>State:</p>
                                                        <p>Delhi</p>
                                                    </div>
                                                    <div className="billingtr">
                                                        <p>Address:</p>
                                                        <p>109, 1st Floor, Mercantile House, 15 K G Marg</p>
                                                    </div>
                                                    <div className="billingtr">
                                                        <p>Pincode:</p>
                                                        <p>110001</p>
                                                    </div>
                                                    <div className="billingtr">
                                                        <p>Mobile:</p>
                                                        <p>9319719547</p>
                                                    </div>
                                                    <div className="billingtr">
                                                        <p>Email:</p>
                                                        <p>info@macamo.in</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-6 col-xl-6">
                                            <div className="shipping">
                                                <h5>Shipping TO</h5>
                                                <div>
                                                    <div className="shippingtr">
                                                        <p>Name:</p>
                                                        <p>{invoicedata?.cust_name}</p>
                                                    </div>
                                                    <div className="shippingtr">
                                                        <p>City:</p>
                                                        <p>{invoicedata?.city}</p>
                                                    </div>
                                                    <div className="shippingtr">
                                                        <p>State:</p>
                                                        <p>{invoicedata?.state}</p>
                                                    </div>
                                                    <div className="shippingtr">
                                                        <p>Address:</p>
                                                        <p>{invoicedata?.address1}</p>
                                                    </div>
                                                    <div className="shippingtr">
                                                        <p>Pincode:</p>
                                                        <p>{invoicedata?.pincode}</p>
                                                    </div>
                                                    <div className="shippingtr">
                                                        <p>Mobile:</p>
                                                        <p>{invoicedata?.cust_phone}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-12 col-lg-12 col-xl-12">
                                            <table className="table table-bordered table-sm">
                                                <thead>
                                                    <tr className="producttr">
                                                        <th>#</th>
                                                        <th>Product Name</th>
                                                        <th>QTY</th>
                                                        <th>Unit Price</th>
                                                        <th>Sub Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {pinvoicedata?.map((pinvoicedata, index) => (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{pinvoicedata?.product_name}</td>
                                                            <td>{pinvoicedata?.product_quantity}</td>
                                                            <td>{pinvoicedata?.product_price/pinvoicedata?.product_quantity}</td>
                                                            <td>{pinvoicedata?.product_price}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            <div className="subtotalsection">
                                                <div className="subtotal">
                                                    <p>Sub Total:</p>
                                                    <p>₹{invoicedata?.subtotal_price}</p>
                                                </div>
                                                <div className="subtotal">
                                                    <p>GST:</p>
                                                    <p>₹0</p>
                                                </div>
                                                <div className="subtotal">
                                                    <p>Shipping Charge:</p>
                                                    <p>₹0</p>
                                                </div>
                                                <div className="subtotal">
                                                    <p>Payment Method:</p>
                                                    <p>{invoicedata?.payment_method}</p>
                                                </div>
                                                <div className="subtotal">
                                                    <p>Grand Total:</p>
                                                    <p><b>₹{invoicedata?.subtotal_price}</b></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default Invoice